<template>
    <Error :errors="errors"/>
    <div class="grid">
		<div class="col-12">
            <div class="card">
                <Survey :survey="1" ref="survey"/>
            </div>
        </div>
    </div>
</template>


<script>
import { mapGetters } from "vuex";
import Survey from '../../components/aktifitas/Index.vue';
import Error from '../../components/Error.vue';

export default {
    components: {
		'Survey': Survey,
		'Error': Error,
	},
    data() {
        return {
        }
    },
    mounted() {
        this.$store.commit('setErrors', {});
    },
    watch: {
    },
    computed:{
        ...mapGetters(['errors']),
    },
    methods: {
    }
}
</script>
